import React from "react";
import Newsletter from "./newsletter";
import cms from "../components/cms/cms";
import companies from "../components/companies";

const Footer = () =>
  <div className="bg-gray-900">
    <div className="max-w-screen-xl mx-auto py-10 px-4 overflow-hidden sm:px-6 lg:px-8">
      <Newsletter />
      <nav className="-mx-5 -my-2 flex flex-wrap justify-center font-medium">
        {
          cms.keyword === "Scala" || cms.keyword === "Rust" ?
            <div className="px-5 py-2">
              <a href="/hiring-solutions" className="text-base leading-6 text-gray-300 hover:text-gray-200">
                Hiring Solutions
              </a>
            </div> : <></>
        }
        <div className="px-5 py-2">
          <a href="/about" className="text-base leading-6 text-gray-300 hover:text-gray-200">
            About us
          </a>
        </div>
        {
          cms.keyword === "Scala" || cms.keyword === "Rust" ?
            <div className="px-5 py-2">
              <a href="/blog" className="text-base leading-6 text-gray-300 hover:text-gray-200">
                Blog
              </a>
            </div> : <></>
        }
        {
          Object.keys(companies).length > 0 ?
            <div className="px-5 py-2">
              <a href="/companies" className="text-base leading-6 text-gray-300 hover:text-gray-200">
                Companies
              </a>
            </div> : <></>
        }
        <div className="px-5 py-2">
          <a href={`mailto:info@${cms.siteUrl}`} className="text-base leading-6 text-gray-300 hover:text-gray-200">
            Contact
          </a>
        </div>
      </nav>
      <div className="mt-6 flex justify-center space-x-6">
        {cms.social.twitter ?
          <a href={cms.social.twitter} target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-gray-200">
            <span className="sr-only">Twitter</span>
            <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a> : <></>
        }
        {cms.social.linkedIn ?
          <a href={cms.social.linkedIn} target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-gray-200">
            <span className="sr-only">LinkedIn</span>
            <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a> : <></>
        }
        {cms.social.reddit ?
          <a href={cms.social.reddit} target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-gray-200">
            <span className="sr-only">Reddit</span>
            <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 11.779c0-1.459-1.192-2.645-2.657-2.645-.715 0-1.363.286-1.84.746-1.81-1.191-4.259-1.949-6.971-2.046l1.483-4.669 4.016.941-.006.058c0 1.193.975 2.163 2.174 2.163 1.198 0 2.172-.97 2.172-2.163s-.975-2.164-2.172-2.164c-.92 0-1.704.574-2.021 1.379l-4.329-1.015c-.189-.046-.381.063-.44.249l-1.654 5.207c-2.838.034-5.409.798-7.3 2.025-.474-.438-1.103-.712-1.799-.712-1.465 0-2.656 1.187-2.656 2.646 0 .97.533 1.811 1.317 2.271-.052.282-.086.567-.086.857 0 3.911 4.808 7.093 10.719 7.093s10.72-3.182 10.72-7.093c0-.274-.029-.544-.075-.81.832-.447 1.405-1.312 1.405-2.318zm-17.224 1.816c0-.868.71-1.575 1.582-1.575.872 0 1.581.707 1.581 1.575s-.709 1.574-1.581 1.574-1.582-.706-1.582-1.574zm9.061 4.669c-.797.793-2.048 1.179-3.824 1.179l-.013-.003-.013.003c-1.777 0-3.028-.386-3.824-1.179-.145-.144-.145-.379 0-.523.145-.145.381-.145.526 0 .65.647 1.729.961 3.298.961l.013.003.013-.003c1.569 0 2.648-.315 3.298-.962.145-.145.381-.144.526 0 .145.145.145.379 0 .524zm-.189-3.095c-.872 0-1.581-.706-1.581-1.574 0-.868.709-1.575 1.581-1.575s1.581.707 1.581 1.575-.709 1.574-1.581 1.574z" />
            </svg>
          </a> : <></>
        }
        <a href="https://wa.me/message/GYLYNS6DBU74B1" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-gray-200">
          <span className="sr-only">WhatsApp</span>
          <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
            <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
          </svg>
        </a>
      </div>
      <p className="mt-6 text-center text-base font-semibold leading-6 text-gray-300">
        &copy; {new Date().getFullYear()} {cms.siteUrl}, All rights reserved.
      </p>
    </div>
  </div>

export default Footer;