import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import cms from "../components/cms/cms";

const Newsletter = () => {
  const [email, setEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(email)
      .then(data => {
        if (data.result === "success") {
          setError(false);
          setSuccess(true);
        } else setError(true);
      });
  }

  return success ? <div className="border-b border-gray-700 pb-6 mb-6 text-center text-green-300 font-semibold">
    <span>Thank you for subscribing</span>
  </div> :
    <div className="border-b border-gray-700 pb-6 mb-6 space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-between">
      <div className="space-y-2">
        <h4 className="text-base leading-5 font-semibold text-gray-200 tracking-wider">
          Subscribe to receive the latest {cms.keyword} jobs in your inbox
  </h4>
        <p className="text-sm leading-6 text-gray-300">
          Receive a weekly overview of {cms.keyword} jobs by subscribing to our mailing list
  </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="sm:flex sm:max-w-md">
          <label forhtml="emailAddress" className="sr-only">Email address</label>
          <input
            onChange={e => setEmail(e.target.value)}
            type="email" id="emailAddress" required className="appearance-none min-w-0 w-full bg-white border border-gray-300 py-2 px-4 text-base leading-6 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline focus:placeholder-gray-400 transition duration-150 ease-in-out sm:max-w-xs" placeholder="you@example.com" />
          <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <button type="submit" className="w-full bg-red-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base leading-6 font-medium text-white hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              Subscribe
              </button>
          </div>
        </div>
        {error && <div className="text-red-600 mt-2 text-center">An error occurred, please try again later</div>}
      </form>
    </div >
}

export default Newsletter;
